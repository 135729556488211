<template>
  <div>
    <div class="page-section-alt">
      <banner :title="meta.title" />

      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="6" class="pb-12 mb-12">
            <v-img src="@/assets/kahev-bagisci-haklari.jpg" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner.vue";

export default {
  components: {
    Banner
  },

  data() {
    return {
      meta: {
        title: "KAHEV Bağışçı Hakları"
      }
    };
  },

  metaInfo() {
    const title = this.meta.title;

    return {
      title: title
    };
  }
};
</script>

<style lang="scss" scoped></style>
